<template>
  <div class="goodsList container">
    <div class="searchbox">
      <el-form inline>
        <el-form-item label="优惠卷名称">
          <el-input
            placeholder="请输入优惠卷名称"
            v-model="coupon_name"
            @keyup.enter.native="searchBtn"
            clearable
            class="input-with-select"
          >
            <!-- <el-button slot="append"
                      @click="getList"
                      icon="el-icon-search"></el-button> -->
          </el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="overdue" placeholder="请选择">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-select v-model="coupon_status" placeholder="请选择">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="getList">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form>
    </div>
    <div>
      <el-button
        type="primary"
        class="addhead"
        @click="toAddDiscount"
        v-if="module_id_array.includes('66') || module_id_array.includes('999')"
        >添加优惠券</el-button
      >
      <el-button
        type=""
        v-if="
          module_id_array.includes('162') || module_id_array.includes('999')
        "
        @click="shareGroup"
      >
        批量分享
      </el-button>
      <el-button
        type=""
        v-if="
          module_id_array.includes('166') || module_id_array.includes('999')
        "
        @click="shareGroupList"
      >
        批量分享记录
      </el-button>
    </div>
    <div class="table-box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; min-width: 1000px"
        :header-cell-style="{ background: '#F5F5F5' }"
        @sort-change="sortChange"
        @select="onSelect"
        @select-all="onSelectAll"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" :selectable="selected">
        </el-table-column>
        <el-table-column label="排序" prop="sort" width="120" sortable="custom">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.sort"
              step-strictly
              :step="1"
              :min="0"
              :max="999999999"
              :controls="false"
              style="width: 80px"
              @change="setSort(scope.row.coupon_data_id, scope.row.sort)"
            ></el-input-number>
          </template>
        </el-table-column>

        <el-table-column label="类型" width="auto" prop="coupon_type">
          <template slot-scope="scope">
            {{ scope.row.coupon_type | couponTypeText }}
          </template>
        </el-table-column>

        <el-table-column label="优惠券名称" width="200" prop="coupon_name">
        </el-table-column>
        <el-table-column label="创建时间" prop="create_time"> </el-table-column>
        <el-table-column label="使用条件">
          <template slot-scope="scope">
            <span v-if="scope.row.coupon_type == 1"
              >满{{ scope.row.money }}减{{ scope.row.enough }}元</span
            >
            <span v-if="scope.row.coupon_type == 2"
              >满{{ scope.row.money }}打{{ scope.row.enough }}折</span
            >
            <span v-if="scope.row.coupon_type == 3"
              >满{{ scope.row.money }}减运费{{ scope.row.enough }}元</span
            >
          </template>
        </el-table-column>

        <el-table-column prop="give_total" label="已发放数量"></el-table-column>
        <el-table-column prop="off_total" label="已使用数量"></el-table-column>
        <el-table-column prop="total" label="剩余发放数"></el-table-column>

        <!-- <el-table-column label="领券中心">
          <template slot-scope="scope">
            <span v-if="scope.row.coupon_centre == 1">上架中</span>
            <span v-if="scope.row.coupon_centre == 0">下架中</span>
          </template>
        </el-table-column> -->

        <el-table-column label="推广方式" width="auto" prop="promotion_way">
          <template slot-scope="scope">
            <span v-if="scope.row.coupon_centre == 1">领券中心</span>
            <span v-if="scope.row.coupon_goods == 1"
              ><span v-if="scope.row.coupon_centre == 1">、</span>商品</span
            >
          </template>
        </el-table-column>
        <el-table-column label="领取截止时间" width="110" prop="show_end_time">
          <template slot-scope="scope">
            <span v-if="scope.row.show_end_time">{{
              dataPars(scope.row.show_end_time)
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="状态">
          <template slot-scope="scope">
            <span
              :style="
                scope.row.is_overdue_str == '已过期' ? 'color:#fe6538;' : ''
              "
              >{{ scope.row.is_overdue_str }}</span
            >
          </template>
        </el-table-column>

        <el-table-column label="是否启用">
          <template slot-scope="scope">
            <span>{{
              scope.row.coupon_status == "1" ? "启用中" : "禁用中"
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="发放状态">
          <template slot-scope="scope">
            <span>{{
              scope.row.is_overdue_str == "未生效"
                ? "未开始"
                : scope.row.is_send == "0"
                ? "已停发"
                : "发放中"
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="230">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="toEditDiscount(scope.row.coupon_data_id)"
              v-if="
                (module_id_array.includes('159') ||
                  module_id_array.includes('999')) &&
                scope.row.is_overdue_str !== '已过期'
              "
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="toGiveDiscount(scope.row.coupon_data_id)"
              v-if="
                (module_id_array.includes('71') ||
                  module_id_array.includes('999')) &&
                scope.row.is_overdue_str == '生效中' &&
                !(
                  scope.row.is_overdue_str !== '未生效' &&
                  scope.row.is_send == '0'
                )
              "
              >发放</el-button
            >

            <el-button
              type="text"
              @click="checkDetile(scope.row.coupon_data_id)"
              v-if="
                (module_id_array.includes('170') || module_id_array.includes('999')) 
              "
              >查看
            </el-button>
            <el-button
              type="text"
              v-if="
                scope.row.is_overdue_str == '生效中' &&
                (module_id_array.includes('999') ||
                  module_id_array.includes('153')) &&
                !(
                  scope.row.is_overdue_str !== '未生效' &&
                  scope.row.is_send == '0'
                ) &&
                scope.row.coupon_status == 1
              "
              @click="shareDetile(scope.row.coupon_data_id)"
              >分享
            </el-button>
            <el-button
              type="text"
              @click="stopGive(scope.row)"
              v-if="
                scope.row.is_overdue_str == '生效中' &&
                scope.row.coupon_status == 1 &&
                (scope.row.is_send == 0 ? scope.row.show_recovery : true) &&
                (module_id_array.includes('999') ||
                  module_id_array.includes('160'))
              "
              >{{ scope.row.is_send == "0" ? "恢复" : "停发" }}
            </el-button>
            <el-button
              type="text"
              @click="updateCouponStatus(scope.row)"
              v-if="
                (module_id_array.includes('68') ||
                  module_id_array.includes('999')) &&
                scope.row.is_overdue_str !== '已过期'
              "
              >{{ scope.row.coupon_status == 1 ? "禁用" : "启用" }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      style="margin: 30px 0 40px 0"
    ></el-pagination>
    <el-dialog title="活动分享" :visible.sync="actShareShow" width="500px">
      <el-form :model="actList" label-width="100px">
        <el-form-item label="分享方式一:">
          <div class="act_one">
            <div class="act_one_text">{{ actList.link }}</div>
            <div class="act_text" style="margin-left: 10px">
              <el-button
                class="tag-read"
                type="text"
                size="medium"
                :data-clipboard-text="actList.link"
                @click="copy(actList.link)"
              >
                复制链接
              </el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="分享方式二:">
          <div class="act_one">
            <div class="act_text">
              <a type="text" @click="downLoad('下载海报')"> 下载海报 </a>
            </div>
            <div class="act_text" style="margin-left: 10px">
              <a type="text" @click="downLoad('下载二维码')"> 下载二维码 </a>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="actShareShow = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      width="550px"
      title="批量分享"
      :visible.sync="shareGroupShow"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="120px"
        ref="ruleForm"
        :rules="rules"
        :model="couponShare"
      >
        <el-form-item label="分享标题：" prop="share_name">
          <el-input
            placeholder="请输入"
            style="width: 350px"
            v-model="couponShare.share_name"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="分享描述：" prop="share_desc">
          <el-input
            placeholder="请输入"
            style="width: 350px"
            v-model="couponShare.share_desc"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="领取页banner：">
          <el-upload
            :show-file-list="false"
            :http-request="uploadImg"
            action="share_banner"
            v-if="couponShare.share_banner == ''"
          >
            <!-- 占位图 -->
            <div class="empty-box" v-if="couponShare.share_banner == ''">
              <img
                src="../../assets/empty.png"
                style="width: 48px; height: 48px"
              />
            </div>
          </el-upload>
          <div
            class="box"
            style="width: 150px; height: 150px; text-align: center"
            v-if="couponShare.share_banner !== ''"
          >
            <el-image
              style="width: 150px; height: 150px; text-align: center"
              fit="fill"
              :src="couponShare.share_banner"
            ></el-image>
            <el-upload
              :show-file-list="false"
              :http-request="
                (parms) => {
                  uploadImg(parms, couponShare.share_banner);
                }
              "
              action="share_banner"
            >
              <div class="shadow column-center column">
                <div>
                  <i
                    class="el-icon-search"
                    @click.stop="showImg(couponShare.share_banner)"
                  ></i>

                  <i
                    class="el-icon-delete"
                    @click.stop="delImg(couponShare.share_banner, 1)"
                  ></i>
                </div>
              </div>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="分享卡片图：">
          <el-upload
            :show-file-list="false"
            :http-request="uploadImg"
            action="share_img"
            v-if="couponShare.share_img == ''"
          >
            <!-- 占位图 -->
            <div class="empty-box" v-if="couponShare.share_img == ''">
              <img
                src="../../assets/empty.png"
                style="width: 48px; height: 48px"
              />
            </div>
          </el-upload>
          <div
            class="box"
            style="width: 150px; height: 150px; text-align: center"
            v-if="couponShare.share_img !== ''"
          >
            <el-image
              style="width: 150px; height: 150px; text-align: center"
              fit="fill"
              :src="couponShare.share_img"
            ></el-image>
            <el-upload
              :show-file-list="false"
              :http-request="
                (parms) => {
                  uploadImg(parms, couponShare.share_img);
                }
              "
              action="share_img"
            >
              <div class="shadow column-center column">
                <div>
                  <i
                    class="el-icon-search"
                    @click.stop="showImg(couponShare.share_img)"
                  ></i>

                  <i
                    class="el-icon-delete"
                    @click.stop="delImg(couponShare.share_img, 2)"
                  ></i>
                </div>
              </div>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div style="width: 100%; text-align: left; margin-left: 150px">
          <el-button type="primary" @click="goToShare('ruleForm')"
            >确定去分享</el-button
          >
        </div>
      </span>
    </el-dialog>
    <!-- 批量分享 -->
    <el-dialog
      title="分享"
      :visible.sync="actShareListShow"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="500px"
      :show-close="false"
    >
      <el-form :model="actListArr" label-width="100px">
        <el-form-item label="分享方式一:">
          <div class="act_one">
            <div class="act_one_text">{{ actListArr.applet_shortLink }}</div>
            <div class="act_text" style="margin-left: 10px">
              <el-button
                class="tag-read"
                type="text"
                size="medium"
                :data-clipboard-text="actListArr.applet_shortLink"
                @click="copy(actListArr.applet_shortLink)"
              >
                复制链接
              </el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="分享方式二:">
          <div class="act_one">
            <div class="act_text">
              <a type="text" @click="downLoadArr('下载二维码')"> 下载二维码 </a>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          v-if="
            backShow &&
            (module_id_array.includes('163') || module_id_array.includes('999'))
          "
          @click="goBack()"
          >上一步</el-button
        >
        <el-button type="primary" @click="closeBox()">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 批量分享记录 -->
    <el-dialog
      title="分享"
      :visible.sync="shareRecordShow"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="600px"
    >
      <div style="height: 400px; overflow: hidden; overflow-y: visible">
        <el-table :data="recordTableData" style="width: 100%">
          <el-table-column prop="share_name" label="分享标题" width="150">
          </el-table-column>
          <el-table-column prop="create_time" label="创建时间" width="180">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="ShareRecord(scope.row.share_id)"
                v-if="
                  scope.row.share_status &&
                  (module_id_array.includes('164') ||
                    module_id_array.includes('999'))
                "
                >分享</el-button
              >
              <el-button
                type="text"
                @click="stopShareRecord(scope.row.share_id)"
                v-if="
                  scope.row.share_status &&
                  (module_id_array.includes('168') ||
                    module_id_array.includes('999'))
                "
                >停止分享</el-button
              >
              <el-button
                type="text"
                @click="recordDetails(scope.row.share_id)"
                v-if="
                  module_id_array.includes('167') ||
                  module_id_array.includes('999')
                "
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="shareRecordShow = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="查看" :visible.sync="recordDetailsShow">
      <el-form label-width="120px" ref="ruleForm" :model="recordData">
        <el-form-item label="分享优惠券：" prop="coupon_name"
          ><div>{{ recordData.coupon_name }}</div> </el-form-item
        ><el-form-item label="分享标题：" prop="share_name"
          ><div>{{ recordData.share_name }}</div>
        </el-form-item>
        <el-form-item label="分享描述：" prop="share_desc"
          ><div>{{ recordData.share_desc }}</div>
        </el-form-item>
        <el-form-item label="领取页banner：">
          <!-- 占位图 -->
          <div class="empty-box" v-if="recordData.share_banner_str == ''">
            <img
              src="../../assets/empty.png"
              style="width: 48px; height: 48px"
            />
          </div>
          <div
            class="box"
            style="width: 150px; height: 150px; text-align: center"
            v-if="recordData.share_banner_str !== ''"
          >
            <el-image
              style="width: 150px; height: 150px; text-align: center"
              fit="fill"
              :src="recordData.share_banner_str"
            ></el-image>
          </div>
        </el-form-item>
        <el-form-item label="分享卡片图：">
          <!-- 占位图 -->
          <div class="empty-box" v-if="recordData.share_img_str == ''">
            <img
              src="../../assets/empty.png"
              style="width: 48px; height: 48px"
            />
          </div>
          <div
            class="box"
            style="width: 150px; height: 150px; text-align: center"
            v-if="recordData.share_img_str !== ''"
          >
            <el-image
              style="width: 150px; height: 150px; text-align: center"
              fit="fill"
              :src="recordData.share_img_str"
            ></el-image>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div style="width: 100%; text-align: left; margin-left: 150px">
          <el-button type="primary" @click="recordDetailsShow = false"
            >关 闭</el-button
          >
        </div>
      </span>
    </el-dialog>
    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false;
        }
      "
      :url-list="imgList"
    />
  </div>
</template>

<script>
import commonUtil from "../../utils/commonUtil.js";
import { uploadImg } from "../../service/common";
import Clipboard from "clipboard";
export default {
  name: "goodsList",
  data() {
    return {
      total: 0,
      page: 1,
      pageNumber: 10,
      coupon_name: "",
      tableData: [],
      module_id_array: window.utils.storage.getter("module_id_array") || [],
      actShareShow: false,
      actList: { link: "" },
      actListArr: { applet_shortLink: "" },
      coupon_data_id: "",
      order_sort: "",
      overdue: "",
      coupon_status: "",
      options1: [
        {
          value: 0,
          label: "未过期",
        },
        {
          value: 1,
          label: "已过期",
        },
      ],
      options2: [
        {
          value: 0,
          label: "禁用",
        },
        {
          value: 1,
          label: "启用",
        },
      ],
      shareGroupShow: false,
      couponShare: {
        coupon_data_id_arr: [],
        share_name: "",
        share_desc: "",
        share_img: "",
        share_img_pic: "",
        share_banner_pic: "",
        share_banner: "",
      },
      recordData: {}, //记录详情
      isEdit: false, //是否是编辑
      showViewer: false, //图片预览
      imgList: [],
      actShareListShow: false,
      backShow: true, //返回上一步是否显示
      selections: {}, // 保存已选择过的row
      nowShareId: "",
      recordTableData: [], //分享记录列表
      shareRecordShow: false, //分享记录弹框
      recordDetailsShow: false, //分享记录查看弹框
      rules: {
        share_name: [
          { required: true, message: "请输入分享标题", trigger: "blur" },
          { min: 0, max: 10, message: "10个字内", trigger: "blur" },
        ],
        share_desc: [
          { required: true, message: "请输入分享描述", trigger: "blur" },
          { min: 0, max: 50, message: "50字内", trigger: "blur" },
        ],
      },
    };
  },
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
    uploadImg,
  },
  filters: {
    couponTypeText(val) {
      switch (val) {
        case 1:
          return "减免券";
        case 2:
          return "折扣券";
        case 3:
          return "运费券";
      }
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.tableData = [];
      let data = {
        page: this.page,
        pageNumber: this.pageNumber,
        coupon_name: this.coupon_name,
        order_sort: this.order_sort,
        overdue: this.overdue,
        coupon_status: this.coupon_status,
      };
      console.log(data);
      this.axios.post("/store/Shopcoupon/getCounponLists", data).then((res) => {
        this.total = res.data.total;
        let list = res.data.list;
        list.forEach((ele) => {
          ele.create_time = this.dayjs
            .unix(ele.create_time)
            .format("YYYY-MM-DD HH:mm");
          ele.total = ele.total == -1 ? "无限" : ele.total;
        });
        this.tableData = list;
        this.checkRows();
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selected(row, index) {
      if (
        row.is_overdue_str == "生效中" &&
        row.coupon_status == "1" &&
        row.is_send == "1"
      ) {
        return true; //可勾选
      } else {
        return false; //不可勾选
      }
    },
    // 勾选时候，记录[{coupon_data_id: row}, ...]
    onSelect(selection, row) {
      console.log("row", row);
      console.log("selection", selection);
      console.log("this.selection", this.selection);
      if (this.selections[row.coupon_data_id]) {
        delete this.selections[row.coupon_data_id];
      } else {
        this.selections[row.coupon_data_id] = row;
      }

      console.log(
        "this.selections[row.coupon_data_id]",
        this.selections[row.coupon_data_id]
      );
      console.log("onSelect", this.selections);
    },

    // 全选状况
    onSelectAll(selection) {
      // 全选
      if (selection.length) {
        selection.forEach((row) => {
          this.selections[row.coupon_data_id] = row;
        });
      } else {
        // 取消全选
        this.tableData.forEach((row) => {
          delete this.selections[row.coupon_data_id];
        });
      }
      console.log("onSelectAll", this.selections);
    },

    // 对已选择过的row勾选，返回到上一页时候
    checkRows() {
      const keys = Object.keys(this.selections);
      console.log("keys", keys);
      console.log("this.tableData", this.tableData);
      const rows = this.tableData.filter((row) => {
        return keys.includes(String(row.coupon_data_id));
      });
      console.log("rows", rows);

      rows.forEach((row) => {
        console.log("row", row);
        this.$nextTick(function () {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      });
      console.log("checkRows", this.selections);
    },
    fmObjToArr(data) {
      let arr = [];
      for (let key in data) {
        arr.push(
          data[key] // label 字段
        );
      }
      return arr;
    },
    shareGroup() {
      this.couponShare = {
        share_name: "",
        share_desc: "",
        share_img_pic: "",
        share_banner_pic: "",
        share_img: "",
        share_banner: "",
      };
      const chooseList = this.fmObjToArr(this.selections);
      console.log("shareGroup", this.fmObjToArr(this.selections));
      if (chooseList.length > 1 && chooseList.length <= 20) {
        this.isEdit = false;
        this.backShow = true;
        this.shareGroupShow = true;
      } else if (chooseList.length < 2) {
        this.$message.error("请至少勾选两个优惠券！");
      } else if (chooseList.length > 20) {
        this.$message.error("请最多勾选20个优惠券！");
      }
    },
    goToShare(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.shareGroupShow = false;
          const list = this.fmObjToArr(this.selections).map((res) => {
            return res.coupon_data_id;
          });
          const data = this.isEdit
            ? {
                share_id: this.nowShareId,
                coupon_data_id_arr: list,
                share_name: this.couponShare.share_name,
                share_desc: this.couponShare.share_desc,
                share_img: this.couponShare.share_img_pic,
                share_banner: this.couponShare.share_banner_pic,
              }
            : {
                coupon_data_id_arr: list,
                share_name: this.couponShare.share_name,
                share_desc: this.couponShare.share_desc,
                share_img: this.couponShare.share_img_pic,
                share_banner: this.couponShare.share_banner_pic,
              };
          console.log("data", data);
          const url = this.isEdit
            ? "/store/Shopcoupon/editCouponShare"
            : "/store/Shopcoupon/creatCouponShare";
          this.axios.post(url, data).then((res) => {
            this.nowShareId = res.data.share_id;
            this.getListShare();
          });
          this.actShareListShow = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 返回上一步getList
    goBack() {
      this.isEdit = true;
      this.actShareListShow = false; //关闭链接分享弹框
      this.shareGroupShow = true; //打开填写弹框
    },
    // 关闭
    closeBox() {
      this.couponShare = {
        coupon_data_id_arr: [],
        share_name: "",
        share_desc: "",
        share_img_pic: "",
        share_banner_pic: "",
        share_img: "",
        share_banner: "",
      };
      this.nowShareId = "";
      this.isEdit = false;
      this.actShareListShow = false;
      this.backShow = true;
      this.selections = {};
      this.$refs.multipleTable.clearSelection();
    },
    getListShare(val) {
      if (val) {
        const data = {
          share_id: val,
        };
        console.log("123", data);
        this.axios
          .post("/store/Shopcoupon/getCouponShareLink", data)
          .then((res) => {
            this.actListArr.applet_shortLink = res.data.applet_shortLink;
          });
        this.backShow = false;
        this.shareRecordShow = false;
        this.actShareListShow = true;
      } else {
        const data = {
          share_id: Number(this.nowShareId),
        };
        console.log("123", data);
        this.axios
          .post("/store/Shopcoupon/getCouponShareLink", data)
          .then((res) => {
            this.actListArr.applet_shortLink = res.data.applet_shortLink;
          });
      }
    },
    //上传图片
    uploadImg(params, item) {
      let file = params.file;
      uploadImg(file)
        .then((res) => {
          if (params.action == "share_banner") {
            this.couponShare.share_banner = res.data.path;
            this.couponShare.share_banner_pic = res.data.pic;
          }
          if (params.action == "share_img") {
            this.couponShare.share_img = res.data.path;
            this.couponShare.share_img_pic = res.data.pic;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //查看图片
    showImg(item) {
      this.imgList = [item];
      this.showViewer = true;
    },
    //删除图片
    delImg(item, type) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (type == 1) {
            this.couponShare.share_banner = "";
            this.couponShare.share_banner_pic = "";
          } else {
            this.couponShare.share_img = "";
            this.couponShare.share_img_pic = "";
          }
        })
        .catch(() => {});
    },
    // 记录列表
    shareGroupList() {
      const data = {};
      this.axios
        .post("/store/Shopcoupon/getCouponShareList", data)
        .then((res) => {
          this.recordTableData = res.data.list;
          this.shareRecordShow = true;
        });
    },
    sortChange(e) {
      let prop = e.prop;
      let order = e.order;
      console.log("123", e);
      console.log("prop", e.prop);
      console.log("order", e.order);
      if (prop == "sort" && order == "ascending") {
        this.order_sort = 0;
      } else if (prop == "sort" && order == "descending") {
        this.order_sort = 1;
      } else {
        this.order_sort = "";
      }
      this.getList();
    },
    reset() {
      commonUtil.lostFocus(event);
      this.page = 1;
      this.pageNumber = 10;
      this.coupon_name = "";
      this.overdue = "";
      this.coupon_status = "";
      this.getList();
    },
    // 分享记录列表内 分享按钮
    ShareRecord(val) {
      this.nowShareId = val;
      this.getListShare(val);
    },
    // 分享记录列表内 停止分享按钮
    stopShareRecord(val) {
      const data = {
        share_id: val,
      };
      this.axios.post("/store/Shopcoupon/stopShare", data).then((res) => {
        this.shareGroupList();
      });
    },
    // 分享记录列表内 查看按钮
    recordDetails(val) {
      this.recordDetailsShow = true;
      const data = {
        share_id: val,
      };
      this.axios
        .post("/store/Shopcoupon/getcouponshareinfo", data)
        .then((res) => {
          this.recordData = res.data;
        });
    },
    searchBtn(e) {
      commonUtil.lostFocus(event);
      let keyCode = window.event ? e.keyCode : e.which;
      if (keyCode == 13) {
        this.getList();
      }
    },
    handleSizeChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getList();
    },
    handleCurrentChange(size) {
      this.page = size;
      this.getList();
    },
    toAddDiscount() {
      commonUtil.lostFocus(event);
      this.$router.push({
        path: "/discounts/addDiscount",
        query: {
          isAdd: 1,
        },
      });
    },
    //修改排序
    setSort(coupon_data_id, sort) {
      let data = {
        coupon_data_id,
        sort,
      };
      this.axios
        .post("/store/Shopcoupon/updateCouponSort", data)
        .then((res) => {
          this.$message.success("修改成功");
          this.getList();
        })
        .catch((err) => {
          console.log(err);
          this.getList();
        });
    },

    //修改状态
    updateCouponStatus(item) {
      commonUtil.lostFocus(event);
      if (item.coupon_status == 1) {
        this.$confirm(
          "禁用后已领取的优惠券也将无法使用哦，请谨慎操作",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          }
        )
          .then(() => {
            let data = {
              coupon_data_id: item.coupon_data_id,
              coupon_status: item.coupon_status == 1 ? 0 : 1,
            };
            this.axios
              .post("/store/Shopcoupon/updateCouponStatus", data)
              .then((res) => {
                this.getList();
                this.$message.success("修改成功");
              });
          })
          .catch(() => {});
      } else {
        let data = {
          coupon_data_id: item.coupon_data_id,
          coupon_status: item.coupon_status == 1 ? 0 : 1,
        };
        this.axios
          .post("/store/Shopcoupon/updateCouponStatus", data)
          .then((res) => {
            this.getList();
            this.$message.success("修改成功");
          });
      }
    },
    toGiveDiscount(coupon_data_id) {
      commonUtil.lostFocus(event);
      this.$router.push({
        path: "/discounts/giveDiscount",
        query: {
          coupon_data_id,
        },
      });
    },
    toEditDiscount(coupon_data_id) {
      commonUtil.lostFocus(event);
      this.$router.push({
        // path: '/discounts/editDiscount',
        path: "/discounts/addDiscount",
        query: {
          coupon_data_id,
          isAdd: 2,
        },
      });
    },
    // 时间转换
    dataPars(val) {
      if (!val) {
        return null;
      }
      var date = new Date(val * 1000);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      return Y + M + D;
    },
    checkDetile(coupon_data_id) {
      this.$router.push({
        path: "/discounts/checkDetails",
        query: {
          coupon_data_id,
          isAdd: "check",
        },
      });
    },
    // 停发
    stopGive(item) {
      console.log("停发", item);
      let data = {
        coupon_data_id: item.coupon_data_id,
        is_send: item.is_send == 1 ? 0 : 1,
      };
      console.log("data", data);
      this.axios
        .post("/store/Shopcoupon/updateCouponSend", data)
        .then((res) => {
          this.getList();
          this.$message.success("修改成功");
        });
    },
    //分享优惠券
    shareDetile(val) {
      this.actShareShow = true;
      this.coupon_data_id = val;
      const data = {
        coupon_data_id: val,
      };
      console.log("data", data);
      this.axios.post("/store/shopcoupon/getShareLink", data).then((res) => {
        this.actList.link = res.data.link;
        console.log("actList", this.actList);
      });
    },
    // 点击复制
    copy(val) {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        console.log("复制成功");
        this.$message.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        if (val == "" || val == null) {
          this.$message.warning("为空文本不支持复制");
        } else {
          console.log("该浏览器不支持自动复制");
          this.$message.warning("该浏览器不支持自动复制");
        }
        // 释放内存
        clipboard.destroy();
      });
    },
    // // 点击下载海报/二维码
    downLoad(name) {
      let data = {
        coupon_data_id: this.coupon_data_id,
      };
      const url =
        name == "下载海报"
          ? "/store/shopcoupon/getcouponshareposter"
          : "/store/shopcoupon/getcouponshareqrcodeimg";
      this.axios.post(url, data).then((res) => {
        let imgsrc = name == "下载海报" ? res.data.img_str : res.data.img_str;
        let image = new Image();
        image.setAttribute("crossOrigin", "anonymous");
        image.src = imgsrc;
        image.onload = () => {
          let canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, image.width, image.height);
          canvas.toBlob((blob) => {
            let imgsrc = URL.createObjectURL(blob);
            this.download1(imgsrc, name);
            // 用完释放URL对象
            URL.revokeObjectURL(imgsrc);
          });
        };
      });
    },
    download1(href, name) {
      let eleLink = document.createElement("a");
      eleLink.download = name;
      eleLink.href = href;
      eleLink.click();
      eleLink.remove();
    },
    downLoadArr(name) {
      let data = {
        share_id: this.nowShareId,
      };
      this.axios
        .post("/store/Shopcoupon/getCouponShareAppletCode", data)
        .then((res) => {
          let imgsrc =
            name == "下载海报" ? res.data.applet_qr : res.data.applet_qr;
          let image = new Image();
          image.setAttribute("crossOrigin", "anonymous");
          image.src = imgsrc;
          image.onload = () => {
            let canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, image.width, image.height);
            canvas.toBlob((blob) => {
              let imgsrc = URL.createObjectURL(blob);
              this.download1(imgsrc, name);
              // 用完释放URL对象
              URL.revokeObjectURL(imgsrc);
            });
          };
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.el-image-viewer__wrapper {
  z-index: 2999 !important;
}
.empty-box {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e4e4;
}
.box {
  position: relative;
  &:hover .shadow {
    display: flex;
  }
  .shadow {
    position: absolute;
    left: 0;
    top: 0;
    width: 150px;
    height: 150px;
    background-color: rgba(0, 0, 0, 0.6);
    display: none;
  }
  i + i {
    margin-left: 30px;
    color: #d44747;
  }
  i {
    color: #ffffff;
    font-size: 30px;
    text-align: center;
    cursor: pointer;
  }
}
.addhead {
}
// .goodsList {
//   .searchbox {
//     .input-with-select {
//       width: 240px;
//       margin-left: 10px;
//     }
//   }
// }
.act_one {
  display: flex;
  align-items: center;
  white-space: nowrap;
  .act_one_text {
    display: flex;
    align-items: center;
    min-width: 200px;
    overflow: hidden;
  }
}
.act_text {
  cursor: pointer;
  color: #4974f5;
  font-size: 14px;
}
</style>
